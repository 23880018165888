import * as types from "./ActionType";

//defining initial state of application
const initialState = {
  accordionId: 1,
  completedSteps: [],
  themes: [],
  clientDetails: {},
  posDetails: [],
  wtProduct: [],
  stThree: {},
  supportingFeatureDetails: [],
  mobileResultSet: false,
  isLoader: false,
  isSave:false,
  defaultPosIndex: 0,
  inputDetails: {},
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NEXT_ACCORDION:
      return {
        ...state,
        accordionId: action.payload,
      };
    case types.UPDATE_ACCORDION_ID:
      return {
        ...state,
        accordionId: action.payload,
      };
    case types.PREV_ACCORDION:
      return {
        ...state,
        accordionId: action.payload,
      };
    case types.COMPLETED_STEPS:
      return {
        ...state,
        completedSteps: [...state.completedSteps, action.payload],
      };
    case types.GET_WINTHEME_DATA:
      return {
        ...state,
        themes: action.payload,
      };
    case types.SET_WINTHEME_DATA:
      return {
        ...state,
        themes: action.payload,
      };
    case types.SET_CLIENT_DETAILS:
      return {
        ...state,

        clientDetails: action.payload,
      };
    case types.SET_POS_DATA:
      return {
        ...state,
        posDetails: action.payload,
      };
    case types.GET_POS_DATA:
      return {
        ...state,
        posDetails: action.payload,
      };
    case types.SET_WT_PRODUCT:
      return {
        ...state,
        wtProduct: action.payload,
      };
    case types.GET_WT_PRODUCT:
      return {
        ...state,
        wtProduct: action.payload,
      };
    case types.SET_ST_THREE:
      return {
        ...state,
        stThree: action.payload,
      };
    case types.GET_ST_THREE:
      return {
        ...state,
        stThree: action.payload,
      };
    case types.SET_SUPPORTING_FEATURE_DATA:
      return {
        ...state,
        supportingFeatureDetails: action.payload,
      };
    case types.GET_SUPPORTING_FEATURE_DATA:
      return {
        ...state,
        supportingFeatureDetails: action.payload,
      };
    case types.SET_RESULT_SET:
      return {
        ...state,
        mobileResultSet: action.payload,
      };
    case types.SET_LOADER:
      return {
        ...state,
        isLoader: action.payload,
      };
    case types.SET_INPUT_DATA:
      return {
        ...state,
        inputDetails: action.payload,
      };
    case types.UPDATE_POS_INDEX:
      return {
        ...state,
        defaultPosIndex: action.payload,
      };
      case types.SET_IS_SAVE:
        return {
          ...state,
          isSave: action.payload,
        };
      case types.UPDATE_IS_SAVE:
        return {
          ...state,
          isSave: action.payload,
        };
    default:
      return state;
  }
};

export default DashboardReducer;
