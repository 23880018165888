export const UPDATE_ACCORDION_ID = "UPDATE_ACCORDION_ID";
export const NEXT_ACCORDION = "NEXT_ACCORDION";
export const PREV_ACCORDION = "PREV_ACCORDION";
export const COMPLETED_STEPS = "COMPLETED_STEPS";
export const SET_MASTER_DATA = "SET_MASTER_DATA";
export const GET_MASTER_DATA = "GET_MASTER_DATA";
export const SET_WINTHEME_DATA = "SET_WINTHEME_DATA";
export const GET_WINTHEME_DATA = "GET_WINTHEME_DATA";
export const SET_CLIENT_DETAILS = "SET_CLIENT_DETAILS";
export const SET_POS_DATA = "SET_POS_DATA";
export const GET_POS_DATA = "GET_POS_DATA";
export const SET_WT_PRODUCT = "SET_WT_PRODUCT";
export const GET_WT_PRODUCT = "GET_WT_PRODUCT";
export const SET_ST_THREE = "SET_ST_THREE";
export const GET_ST_THREE = "GET_ST_THREE";
export const GET_SUPPORTING_FEATURE_DATA = "GET_SUPPORTING_FEATURE_DATA";
export const SET_SUPPORTING_FEATURE_DATA = "SET_SUPPORTING_FEATURE_DATA";
export const SET_RESULT_SET = "SET_RESULT_SET";
export const SET_LOADER = "SET_LOADER";
export const SET_INPUT_DATA = "SET_INPUT_DATA";
export const UPDATE_POS_INDEX = "UPDATE_POS_INDEX";
export const SET_IS_SAVE = "SET_IS_SAVE";
export const UPDATE_IS_SAVE = "UPDATE_IS_SAVE";
