import { Suspense } from "react";
import { lazy } from "react";
import { Route, Routes } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import "./Assets/font/GothamBold.ttf";
import "./Assets/font/GothamBook.ttf";
import "./Assets/font/GothamLight.ttf";
import "./Assets/font/GothamMedium.ttf";
import { useSelector } from "react-redux";
const Dashboard = lazy(() => import("./Container/Dashboard"));

function App() {
  const { isLoader } = useSelector((state) => state.Reducer);
  return (
    <div className="App">
      {isLoader === true ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        >
          <CircularProgress
            style={{
              color: "#DA291C",
              width: "70px",
              height: "70px",
              textAlign: "center",
              marginTop: "20%",
              zIndex: "999",
              position: "absolute",
            }}
          />
        </div>
      ) : (
        ""
      )}

      <ToastContainer
        style={{
          alignItems: "center",
          fontSize: "small",
          width: "auto",
          maxHeight: "1em",
        }}
        position="top-center"
        autoClose={4000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <Suspense
        fallback={
          <div
          // style={{
          //   textAlign: "center",
          //   marginTop: "20%",
          // }}
          >
            {" "}
            <CircularProgress
              style={{
                textAlign: "center",
                marginTop: "20%",
                color: "#DA291C",
                width: "70px",
                height: "70px",
              }}
            />
          </div>
        }
      >
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
